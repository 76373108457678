import {Section} from '@components/Section';
import {Container} from '@components/Container';
import {Typography} from '@components/Typography';
import {Row} from '@components/Row';
import {Box, Button, Col, HeroIntro, Icon, StepCard} from '@components/index';
import {FormattedMessage, useIntl} from 'react-intl';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useLocale} from '@utils/locale';
import bgImg from 'images/404/cover_desktop_B_1872x758px.jpg';
import bgImgTablet from 'images/404/cover_tablet_B_752x305px.jpg';
import bgImgMobile from 'images/404/cover_mobil_B_375x555px.jpg';
import {HeroIntroBackroundMTD} from '@liveComponents/BackgroundImage/HeroIntroBackroundMTD';
import React from 'react';
import Head from 'next/head';

const Page404 = () => {
    const locale = useLocale();
    const intl = useIntl();

    return (
        <>
            <Head>
                <title>{intl.formatMessage({defaultMessage: 'Stránka nenalezena'})}</title>
            </Head>
            <Section className="mb-10" centerDrawnBackground>
                <Container variant="wide">
                    <HeroIntro variant="lp">
                        <HeroIntroBackroundMTD
                            bgImg={bgImg}
                            bgImgTablet={bgImgTablet}
                            bgImgMobile={bgImgMobile}
                            alt={intl.formatMessage({defaultMessage: 'Stránka nenalezena'})}
                        />

                        <HeroIntro.Content>
                            <HeroIntro.Bottom>
                                <Box className="ps-md-5 pt-md-3 ps-lg-8">
                                    <HeroIntro.Heading variant="h1">
                                        <FormattedMessage defaultMessage={'Téhle stránce chybí střecha nad hlavou'} />
                                    </HeroIntro.Heading>

                                    <Typography as="p" variant="h4" className="mb-10 mb-lg-24">
                                        <FormattedMessage
                                            defaultMessage={'Bohužel, hledanou stránku jsme nenašli. Nemáte ve své adrese překlep?'}
                                        />
                                    </Typography>

                                    <Link href={getLocalizedRoute(routes.homepage, locale)} passHref legacyBehavior>
                                        <Button>
                                            <FormattedMessage defaultMessage={'Zpět na úvodní stránku'} />
                                        </Button>
                                    </Link>
                                </Box>
                            </HeroIntro.Bottom>
                        </HeroIntro.Content>
                    </HeroIntro>
                </Container>
            </Section>

            <Section>
                <Container className="mb-last-0">
                    <Typography as="h2" variant="h2" className="mb-5 mb-lg-10">
                        <FormattedMessage defaultMessage={'Co vás nyní čeká?'} />
                    </Typography>

                    <Row className="g-4 g-lg-8" colsNumber="12">
                        <Col md={6} xl={4}>
                            <StepCard>
                                <StepCard.Heading>
                                    <Typography as="h3" variant="h2">
                                        <Icon.Reload size="xs" color="red" />
                                    </Typography>
                                </StepCard.Heading>

                                <StepCard.SubHeading>
                                    <Typography as="p" variant="perex-lg">
                                        <FormattedMessage defaultMessage={'Zkuste to od začátku'} />
                                    </Typography>
                                </StepCard.SubHeading>

                                <StepCard.Description>
                                    <Typography as="p" variant="perex">
                                        <Typography>
                                            <FormattedMessage
                                                defaultMessage="Nejsnazší cesta je zkusit hledat od začátku na <link>Úvodní stránce</link>."
                                                values={{
                                                    link: (chunks: any) => (
                                                        <Link href={getLocalizedRoute(routes.homepage, locale)}>{chunks}</Link>
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                    </Typography>
                                </StepCard.Description>
                            </StepCard>
                        </Col>

                        <Col md={6} xl={4}>
                            <StepCard>
                                <StepCard.Heading>
                                    <Typography as="h3" variant="h2">
                                        <Icon.Search size="xs" color="red" />
                                    </Typography>
                                </StepCard.Heading>

                                <StepCard.SubHeading>
                                    <Typography as="p" variant="perex-lg">
                                        <FormattedMessage defaultMessage={'Hledáte nemovitost?'} />
                                    </Typography>
                                </StepCard.SubHeading>

                                <StepCard.Description>
                                    <Typography as="p" variant="perex">
                                        <Typography>
                                            <FormattedMessage
                                                defaultMessage="Pokud hledáte nemovitost, tak stránka <link>Tipy pro zájemce</link> vám poradí, jak na to."
                                                values={{
                                                    link: (chunks: any) => (
                                                        <Link href={getLocalizedRoute(routes.serviceCentreIntroCustomer, locale)}>
                                                            {chunks}
                                                        </Link>
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                    </Typography>
                                </StepCard.Description>
                            </StepCard>
                        </Col>

                        <Col md={6} xl={4}>
                            <StepCard>
                                <StepCard.Heading>
                                    <Typography as="h3" variant="h2">
                                        <Icon.DocumentHouse size="xs" color="red" />
                                    </Typography>
                                </StepCard.Heading>

                                <StepCard.SubHeading>
                                    <Typography as="p" variant="perex-lg">
                                        <FormattedMessage defaultMessage={'Nabízíte nemovitost?'} />
                                    </Typography>
                                </StepCard.SubHeading>

                                <StepCard.Description>
                                    <Typography as="p" variant="perex">
                                        <Typography>
                                            <FormattedMessage
                                                defaultMessage={
                                                    'Chystáte se prodat nebo pronajmout svou nemovitost? <link>Začněte zde</link>.'
                                                }
                                                values={{
                                                    link: (chunks: any) => (
                                                        <Link href={getLocalizedRoute(routes.insert, locale)}>{chunks}</Link>
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                    </Typography>
                                </StepCard.Description>
                            </StepCard>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Page404;
