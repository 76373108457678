import classNames from 'classnames';
import React, {FC} from 'react';
import styles from './StepCard.module.scss';
import {StepCardDescription} from './StepCardDescription';
import {StepCardHeading} from './StepCardHeading';
import {StepCardSubHeading} from './StepCardSubHeading';

export type StepCardProps = {
    className?: string;
    noBorder?: boolean;
};

export const StepCard: FC<React.PropsWithChildren<StepCardProps>> = ({className, noBorder, children}) => {
    return (
        <article className={classNames(styles.stepCard, 'stepCard h-100', className, {[styles['stepCard--no-border']]: noBorder})}>
            {children}
        </article>
    );
};

export default Object.assign(StepCard, {
    Heading: StepCardHeading,
    SubHeading: StepCardSubHeading,
    Description: StepCardDescription,
});
