import {Section} from '@components/Section';
import {Container} from '@components/Container';
import {Typography} from '@components/Typography';
import {Box, HeroIntro} from '@components/index';
import React, {FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Related, RelatedAdvertsType, RelatedProps} from '@pageComponents/Detail/Related';
import bgImg from 'images/404/cover_desktop_1872x758px.jpg';
import bgImgTablet from 'images/404/cover_tablet_752x305px.jpg';
import bgImgMobile from 'images/404/cover_mobil_375x555px.jpg';
import {HeroIntroBackroundMTD} from '@liveComponents/BackgroundImage/HeroIntroBackroundMTD';
import Head from 'next/head';

type Detail404Props = {
    relatedAdverts: RelatedAdvertsType;
    relatedAdvertsParameters: RelatedProps['advertParameters'];
};

const Detail404: FC<React.PropsWithChildren<Detail404Props>> = ({relatedAdverts, relatedAdvertsParameters}) => {
    const intl = useIntl();

    return (
        <>
            <Head>
                <title>{intl.formatMessage({defaultMessage: 'Inzerát nenalezen'})}</title>
            </Head>
            <Section className="mb-10" centerDrawnBackground>
                <Container variant="wide">
                    <HeroIntro variant="lp">
                        <HeroIntroBackroundMTD
                            bgImg={bgImg}
                            bgImgTablet={bgImgTablet}
                            bgImgMobile={bgImgMobile}
                            alt={intl.formatMessage({defaultMessage: 'Tuto nemovitost zde již nemáme'})}
                        />

                        <HeroIntro.Content>
                            <HeroIntro.Bottom>
                                <Box className="ps-md-5 pt-md-3 ps-lg-8">
                                    <HeroIntro.Heading variant="h1">
                                        <FormattedMessage defaultMessage={'Tuto nemovitost zde již nemáme'} />
                                    </HeroIntro.Heading>

                                    <Typography as="p" variant="h4" className="mb-10 mb-lg-24">
                                        <FormattedMessage
                                            defaultMessage={'Inzerát byl již smazán, ale můžete prozkoumat podobné nabídky'}
                                        />
                                    </Typography>
                                </Box>
                            </HeroIntro.Bottom>
                        </HeroIntro.Content>
                    </HeroIntro>
                </Container>
            </Section>

            <Section>
                <Container>
                    <Related
                        title={intl.formatMessage({defaultMessage: 'Podobné nabídky jako tato nemovitost'})}
                        adverts={relatedAdverts}
                        advertParameters={relatedAdvertsParameters}
                    />
                </Container>
            </Section>
        </>
    );
};

export default Detail404;
