import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdvertRelatedQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  locale: Types.Locale;
  relatedAdvertLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type AdvertRelatedQuery = { __typename?: 'Query', advert?: { __typename?: 'Advert', relatedAdverts?: { __typename?: 'AdvertList', list?: Array<{ __typename?: 'Advert', id?: string | null, address?: string | null, uri?: string | null, offerType?: Types.OfferType | null, estateType?: Types.EstateType | null, disposition?: Types.Disposition | null, surface?: number | null, surfaceLand?: number | null, price?: number | null, charges?: number | null, currency?: Types.Currency | null, charity?: boolean | null, imageAltText?: string | null, originalPrice?: number | null, isDiscounted?: boolean | null, isNew?: boolean | null, type?: Types.AdvertImportType | null, dataJson?: string | null, mainImage?: { __typename?: 'Image', url?: string | null } | null, project?: { __typename?: 'Project', id?: string | null } | null } | null> | null } | null, relatedAdvertParameters?: { __typename?: 'RelatedAdvertParameters', disposition?: Array<Types.Disposition | null> | null, estateType?: Types.EstateType | null, offerType?: Types.OfferType | null } | null } | null };


export const AdvertRelatedDocument = gql`
    query AdvertRelated($id: ID!, $locale: Locale!, $relatedAdvertLimit: Int = 6) {
  advert(id: $id) {
    relatedAdverts(limit: $relatedAdvertLimit) {
      list {
        id
        address(locale: $locale)
        uri
        offerType
        estateType
        disposition
        surface
        surfaceLand
        price
        charges
        currency
        charity
        imageAltText(locale: $locale)
        mainImage {
          url(filter: RECORD_THUMB)
        }
        project {
          id
        }
        originalPrice
        isDiscounted
        isNew
        type
        dataJson
      }
    }
    relatedAdvertParameters {
      disposition
      estateType
      offerType
    }
  }
}
    `;

/**
 * __useAdvertRelatedQuery__
 *
 * To run a query within a React component, call `useAdvertRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertRelatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      relatedAdvertLimit: // value for 'relatedAdvertLimit'
 *   },
 * });
 */
export function useAdvertRelatedQuery(baseOptions: Apollo.QueryHookOptions<AdvertRelatedQuery, AdvertRelatedQueryVariables> & ({ variables: AdvertRelatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdvertRelatedQuery, AdvertRelatedQueryVariables>(AdvertRelatedDocument, options);
      }
export function useAdvertRelatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdvertRelatedQuery, AdvertRelatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdvertRelatedQuery, AdvertRelatedQueryVariables>(AdvertRelatedDocument, options);
        }
export function useAdvertRelatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdvertRelatedQuery, AdvertRelatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdvertRelatedQuery, AdvertRelatedQueryVariables>(AdvertRelatedDocument, options);
        }
export type AdvertRelatedQueryHookResult = ReturnType<typeof useAdvertRelatedQuery>;
export type AdvertRelatedLazyQueryHookResult = ReturnType<typeof useAdvertRelatedLazyQuery>;
export type AdvertRelatedSuspenseQueryHookResult = ReturnType<typeof useAdvertRelatedSuspenseQuery>;
export type AdvertRelatedQueryResult = Apollo.QueryResult<AdvertRelatedQuery, AdvertRelatedQueryVariables>;