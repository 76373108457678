import {PropsWithChildren} from 'react';
import styles from './StepCard.module.scss';
import classNames from 'classnames';

type StepCardHeadingProps = {
    className?: string;
} & PropsWithChildren;

export const StepCardHeading = ({className, children}: StepCardHeadingProps) => {
    return <div className={classNames(styles.stepCardHeading, className)}>{children}</div>;
};
