import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './FilterList.module.scss';
import { FilterListItem } from './FilterListItem';

export const FilterList: FC<React.PropsWithChildren> = ({ children }) => {
	return <ul className={classNames(styles.filterList, 'filterList')}>{children}</ul>;
};

export default Object.assign(FilterList, {
	Item: FilterListItem,
});
