import React, {ComponentProps, FC} from "react";
import {Modal} from "@components/Modal";
import {Typography} from "@components/Typography";
import {FormattedMessage} from "react-intl";
import {Button} from "@components/Button";

type Props = ComponentProps<typeof Modal>;

export const RequestsBlockedModal: FC<Props> = (props) => {
    return (
        <Modal {...props}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Typography as="h2" variant="subheadline" className="mb-0">
                        <FormattedMessage defaultMessage={"Váš prohlížeč blokuje součásti aplikace"}/>
                    </Typography>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"p-5"}>
                <Typography as={"p"} variant={"body"}>
                    <FormattedMessage defaultMessage={"Zdá se, že Váš prohlížeč blokuje součásti aplikace, které jsou nezbytbé k jejímu správnému běhu."} />
                </Typography>
                <Typography as={"p"} variant={"body"}>
                    <FormattedMessage defaultMessage={"Toto chování je zpravidla způsobeno instalovanými rozšířeními, které upravují chování webu. {br}Typickým příkladem jsou různá rozšíření pro blokování reklam (Adblock, Ghostery, apod.), která mohou díky použité terminologii na webu vyhodnotit součásti aplikace nesprávně jako reklamu a tím ji zablokovat."} />
                </Typography>
                <Typography as={"p"} variant={"body"}>
                    <FormattedMessage defaultMessage={"Používáte-li nějaké takové rozšíření, jeho vypnutím, či dodatečnou konfigurací můžete docílit toho, že náš web opět začne fungovat. Případně tento problém řešte s vývojářem vašeho rozšíření. V situaci, kdy Vám naše stránky blokuje vlastní rozšíření Vám totiž nejsme schopni pomoci, jelikož se nejedná o chybu na naší straně."} />
                </Typography>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} onClick={props.onHide}>
                    <FormattedMessage defaultMessage={"Rozumím"} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
