import NextErrorComponent, {ErrorProps} from 'next/error';

import * as Sentry from '@sentry/nextjs';
import Page404 from '@pageComponents/Error/404';
import {NextPageContext} from 'next';
import {nonNullArray, propertyRequiredArray, verifyLocale} from 'src/types/general';
import Detail404 from '@pageComponents/Error/404Detail';
import {isRoute, routes} from '@utils/routes';
import {RelatedAdvertsType, RelatedProps} from '@pageComponents/Detail/Related';
import {initializeApollo} from '@utils/apollo-client';
import {AdvertRelatedDocument, AdvertRelatedQuery, AdvertRelatedQueryVariables} from '@gql/query/advert/related.gql';
import {getGraphQlLocale} from '../types/graphql/general';
import Page500 from '@pageComponents/Error/500';
import {ApolloError} from '@apollo/client';
import Page503 from '@pageComponents/Error/503';

type MyErrorProps = {
    statusCode: number;
    hasGetInitialPropsRun?: boolean;
    err?: any;
    isDetail404?: boolean;
    detailRelatedAdverts?: RelatedAdvertsType;
    detailRelatedAdvertParameters?: RelatedProps['advertParameters'];
    blocked?: boolean;
} & ErrorProps;

const MyError = ({
    statusCode,
    hasGetInitialPropsRun,
    err,
    isDetail404,
    detailRelatedAdverts,
    detailRelatedAdvertParameters,
    blocked,
}: MyErrorProps) => {
    if (!hasGetInitialPropsRun && err) {
        // getInitialProps is not called in case of
        // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
        // err via _app.js so it can be captured
        Sentry.captureException(err);
        // Flushing is not required in this case as it only happens on the client
    }

    if (isDetail404) {
        return <Detail404 relatedAdverts={detailRelatedAdverts ?? []} relatedAdvertsParameters={detailRelatedAdvertParameters} />;
    } else if (statusCode === 404) {
        return <Page404 />;
    }

    if (statusCode === 503) {
        return <Page503 />;
    }

    return <Page500 blocked={blocked} />;
    //return <NextErrorComponent statusCode={statusCode} />;
};

MyError.getInitialProps = async ({res, err, asPath, req, locale}: NextPageContext) => {
    // @ts-expect-error
    const errorInitialProps: MyErrorProps = await NextErrorComponent.getInitialProps({
        res,
        err,
    });
    errorInitialProps.isDetail404 =
        errorInitialProps.statusCode === 404 && !!req?.url && isRoute(req.url, routes.detail, verifyLocale(locale));

    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    errorInitialProps.hasGetInitialPropsRun = true;

    if (err?.message === 'Failed to fetch' && navigator.onLine) {
        errorInitialProps.blocked = true;
    }

    if (err instanceof ApolloError) {
        if (err.networkError && 'statusCode' in err.networkError && err.networkError.statusCode === 503) {
            errorInitialProps.statusCode = 503;
            errorInitialProps.err = {
                statusCode: 503,
                message: 'Server is in maintenance',
                name: 'Server is in maintenance',
            };
            if (res) {
                res.statusCode = 503;
            }
        }
    }

    if (errorInitialProps.isDetail404) {
        const id = req?.url?.split('/').at(-1)?.split('-')?.at(0);
        const client = initializeApollo();
        const {data} = await client.query<AdvertRelatedQuery, AdvertRelatedQueryVariables>({
            query: AdvertRelatedDocument,
            variables: {
                id: id ?? '',
                locale: getGraphQlLocale(locale),
            },
        });

        errorInitialProps.detailRelatedAdverts = propertyRequiredArray(nonNullArray(data.advert?.relatedAdverts?.list ?? []), [
            'id',
            'address',
            'uri',
            'offerType',
            'estateType',
            'disposition',
            'surface',
            'surfaceLand',
            'price',
            'charges',
            'currency',
            'mainImage',
            'isDiscounted',
            'originalPrice',
            'dataJson',
            'type',
        ]);

        errorInitialProps.detailRelatedAdvertParameters = propertyRequiredArray(
            [
                Object.assign({}, data.advert?.relatedAdvertParameters ?? {}, {
                    disposition: nonNullArray(data.advert?.relatedAdvertParameters?.disposition ?? []),
                }),
            ],
            ['offerType', 'estateType', 'disposition'],
        )[0];

        return errorInitialProps;
    } else if (errorInitialProps.statusCode === 404) {
        return errorInitialProps;
    }

    // Running on the server, the response object (`res`) is available.
    //
    // Next.js will pass an err on the server if a page's data fetching methods
    // threw or returned a Promise that rejected
    //
    // Running on the client (browser), Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html

    if (err) {
        Sentry.captureException(err);

        // Flushing before returning is necessary if deploying to Vercel, see
        // https://vercel.com/docs/platform/limits#streaming-responses
        await Sentry.flush(2000);

        return errorInitialProps;
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    Sentry.captureException(new Error(`_error.tsx getInitialProps missing data at path: ${asPath}`));
    await Sentry.flush(2000);

    return errorInitialProps;
};

export default MyError;
