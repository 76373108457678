import {Section} from '@components/Section';
import {Container} from '@components/Container';
import {Typography} from '@components/Typography';
import {Box, Button, HeroIntro} from '@components/index';
import {FormattedMessage, useIntl} from 'react-intl';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useLocale} from '@utils/locale';
import bgImg from 'images/404/cover_desktop_1872x758px.jpg';
import bgImgTablet from 'images/404/cover_tablet_752x305px.jpg';
import bgImgMobile from 'images/404/cover_mobil_375x555px.jpg';
import {HeroIntroBackroundMTD} from '@liveComponents/BackgroundImage/HeroIntroBackroundMTD';
import {ReactNode, useState} from 'react';
import {RequestsBlockedModal} from '@liveComponents/Modal/RequestsBlockedModal';

type Page500Props = {
    blocked?: boolean;
};

const Page500 = ({blocked}: Page500Props) => {
    const locale = useLocale();
    const intl = useIntl();
    const [showBlockedModal, setShowBlockedModal] = useState<boolean>(!!blocked);

    return (
        <>
            <Section className="mb-10" centerDrawnBackground>
                <Container variant="wide">
                    <HeroIntro variant="lp">
                        <HeroIntroBackroundMTD
                            bgImg={bgImg}
                            bgImgTablet={bgImgTablet}
                            bgImgMobile={bgImgMobile}
                            alt={intl.formatMessage({defaultMessage: 'Něco se pokazilo'})}
                        />

                        <HeroIntro.Content>
                            <HeroIntro.Bottom>
                                <Box className="ps-md-5 pt-md-3 ps-lg-8">
                                    <HeroIntro.Heading variant="h1">
                                        <FormattedMessage defaultMessage="Něco se pokazilo" />
                                    </HeroIntro.Heading>

                                    {!blocked && (
                                        <Typography as="p" variant="h4" className="mb-10 mb-lg-24">
                                            <FormattedMessage
                                                defaultMessage="Zkuste to prosím později, popřípadě kontaktujte naši zákaznickou podporu <link>info@bezrealitky.cz</link>."
                                                values={{link: (chunk: ReactNode) => <a href={`mailto: ${chunk}`}>{chunk}</a>}}
                                            />
                                        </Typography>
                                    )}

                                    <Link href={getLocalizedRoute(routes.homepage, locale)} passHref legacyBehavior>
                                        <Button>
                                            <FormattedMessage defaultMessage="Zpět na úvodní stránku" />
                                        </Button>
                                    </Link>
                                </Box>
                            </HeroIntro.Bottom>
                        </HeroIntro.Content>
                    </HeroIntro>

                    {blocked && (
                        <RequestsBlockedModal
                            show={showBlockedModal}
                            onHide={() => {
                                setShowBlockedModal(false);
                            }}
                        />
                    )}
                </Container>
            </Section>
        </>
    );
};

export default Page500;
