import {Section} from '@components/Section';
import {Container} from '@components/Container';
import {Typography} from '@components/Typography';
import {Box, HeroIntro} from '@components/index';
import {FormattedMessage, useIntl} from 'react-intl';
import bgImg from 'images/404/cover_desktop_1872x758px.jpg';
import bgImgTablet from 'images/404/cover_tablet_752x305px.jpg';
import bgImgMobile from 'images/404/cover_mobil_375x555px.jpg';
import {HeroIntroBackroundMTD} from '@liveComponents/BackgroundImage/HeroIntroBackroundMTD';
import Script from 'next/script';

const Page503 = () => {
    const intl = useIntl();
    return (
        <>
            <Section className="mb-10" centerDrawnBackground>
                <Container variant="wide">
                    <HeroIntro variant="lp">
                        <HeroIntroBackroundMTD bgImg={bgImg} bgImgTablet={bgImgTablet} bgImgMobile={bgImgMobile}
                                               alt={intl.formatMessage({defaultMessage: "Údržba"})} />

                        <HeroIntro.Content>
                            <HeroIntro.Bottom>
                                <Box className="ps-md-5 pt-md-3 ps-lg-8">
                                    <HeroIntro.Heading variant="h1">
                                        <FormattedMessage defaultMessage={'Údržba'} />
                                    </HeroIntro.Heading>

                                    <Typography as="p" variant="h4" className="mb-10 mb-lg-24">
                                        <FormattedMessage defaultMessage={'Na serveru právě probíhá údržba. Zkuste to prosím později.'} />
                                    </Typography>
                                </Box>
                            </HeroIntro.Bottom>
                        </HeroIntro.Content>
                    </HeroIntro>
                </Container>
            </Section>

            {/* BEWARE: mighty hack that changes __NEXT_DATA__ before it's used by react hydration */}
            <Script id={"503"} strategy={"beforeInteractive"}>
                {`
                    document.addEventListener("readystatechange", function(event) {
                        if (document.readyState === "interactive") {
                            const dataElem = document.getElementById('__NEXT_DATA__');
                            const data = JSON.parse(dataElem.textContent);
                            data.err = {name: "Server is in maintenance", message: "503 - Server is in maintenance", statusCode: 503};
                            dataElem.textContent = JSON.stringify(data);
                            window.__NEXT_DATA__ = data;
                        }
                    });
                `}
            </Script>
        </>
    );
};

export default Page503;
